/* Contains the ObsList Component : the table displaying all observation saved in the database*/

import React, { Component, Fragment } from "react";
import { Table } from "reactstrap";
import ObsModal from "./ObsModal";
import ConfirmRemoval from "./ConfirmRemovalBtn";
import axios from "axios";

axios.defaults.withCredentials = true

class ObsList extends Component {
  
  render() {
    //console.log("PROPS IN OBSList.js", this.props)
    const observations = this.props.observations;
    let superuser = this.props.superuser;    
    const options = {  year: 'numeric', month: 'long', day: 'numeric' }; 
    // weekday: 'long',
                     
    //render the list of all observations, with a header row first
    return (
      <Table border = '1'>
            
          <thead>
            <tr>
              <th>Id</th>
              <th colSpan="2">Organism</th>
              <th>Site</th>
              <th>Lat</th>
              <th>Long</th>
              <th>Quantity</th>  
              <th colSpan="2">Date</th>
              <th>Seen by</th>
              </tr>
          </thead>
          
          <tbody>
        
            {(!observations || observations.length <= 0) ? (
                    <tr>
                    <td colSpan="6" align="center" color='black'>
                        <b>No observations yet</b>
                    </td>
                    </tr>
                ) : (
                
                          
                observations.map(observation => (
                <Fragment>
                    <tr key={observation[1].id} className = 'bottom_border2'>
                        
                        <td>{observation[1].id}</td>
                         
                        <td colSpan="2">{observation[1].organism.genus} {observation[1].organism.sp}{observation[1].organism.variety}</td> 
                        <td>{observation[1].sitename}</td>
                        <td>{observation[1].lat}</td>
                        <td>{observation[1].lon}</td>
                        <td>{observation[1].abundance}</td>
                        
                        {/* THIS NEXT LINE IS SO DATE DISPLAYS WITH MONTH IN LETTERS */}
                        <td colSpan="2">{new Date(observation[1].display_date).toLocaleDateString('en-nz', options)}</td> 
                        <td>{observation[1].observer}</td>
                    </tr>
                
                    <tr className = 'bottom_border1'> 
                            <td colSpan = "6" id='xnote' >Note: {observation[1].note}</td>
                            <td colSpan = "2" >{observation[1].imageURL1}</td>
                            <td align="center">
                            {/* NB re-rendering of this Edit button is controlled from ObsModal  */}
                                <ObsModal 
                                    create={false}
                                    observation={observation[1]}
                                    superuser={superuser}
                                    resetState={this.props.resetState}  // This should trigger a reload of the edited observation ???????????
                               /> 
                            </td>
                            <td>
                            {/* Render "Remove" button only if a superuser is logged in */}    
                            {superuser && (superuser[0] === true) ?
                                    
                                (<ConfirmRemoval
                                    // specify the id  
                                    id={observation[1].id}
                                    resetState={this.props.resetState}
                                 />   
                                ): <span></span> }    
                            </td>
                        
                    </tr>
                  </Fragment>
                  ))) 
                  
                }
          
          </tbody>
                 
      </Table>

    );
  }
}

export default ObsList;
