/* This parent component controls the left hand panel, with forms for filtering observations and a key to abundance (see Legend function)
It gets organisms and observations from the database and sends observations to its child components as props */

import React, { Component, Fragment } from "react";
import { Button, Form, Input, Label, Table} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import MyMap from "./Components/MyMap";
//import Header from "./Components/Header";
import Sidebar from './Components/Sidebar';
import './App.css';
import './Sidebar.css';
import './index.css';
import axios from "axios";
import { API_URL, API_URL2 } from "./constants";
import { format } from 'date-fns'
import moment from 'moment'

axios.defaults.withCredentials = true

class App extends Component {

  constructor(props){
    super(props)
    this.state = {
        idOrg: 0,
        orgName: '0: x x',
        organism: 0,
        id:0,
        date: moment().locale('en-nz').format('l'),  // this is the date in std NZ format
        display_date: null,                          // this is the date that will be displayed in the table of Sidebar's RH panel  
        sitename: "",
        lat: this.props.lat,
        lon: this.props.lon,
        abundance: 0,
        note: "",
        observer: "CHH",
        recorder: 1,
        color: 'white',                                // marker color
        dropdownItems: [],                             // List of organisms extracted from the database
        dropdownItems2: [],                            // List of observers extracted from the database
        dropdownObs:[],                                // list of observations
        value : ['coconut'],                           // the array of organisms selected in the form
        valueOrg: 'XXX',                               // the value selected in the Observers form
        idObs: ['0',],                                 // the array of observation IDs selected in the observatiosn forms
        orgFilter: null,                               // filtered organisms  to use in URL
        chosenObserver: null,                          // selected observer to use in URL
        chosenObservation: null,                       // selected observations to use in URL
        chosenAb: false,                               // boolean indicates whether only abundant occurrences are being sought (rather than all occurrences)
        chosenPr:false,                                // boolean indicates whether only all occurrences but not absences are being sought 
        superuser: null,                               // boolean that indicates whether the logged-in user is a superuser
        reload:false,
        loaded:false,
        marker: false,
    };

    // This binding is necessary to make `this` work in the callback
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
    this.handleSubmit3 = this.handleSubmit3.bind(this);
    this.handleButton=this.handleButton.bind(this);
    this.handleId = this.handleId.bind(this);
    this.handleClick = this.handleClick.bind(this);
    
  }

  componentDidMount() {
    this.resetState();
    this.getUserStatus();
    // ensure the default on loading of map is for a marker  with a lat/lon tooltip to appear when the cursor is double clicked
    this.setState({marker:true});
    console.log('toggle for marker btn:', this.state.marker)
  };

  // handle clicking of the button for cusror dbl click functionality (create observation or show marker) by toggling true/false state of marker 
  handleClick() {
    this.setState({
      marker: !this.state.marker,
    });  
}

  resetState = () => {
    this.getObs();
    this.getOrganisms();
    this.getObservers();
    this.getIds();
  };

  getObs = ()  =>{
    //console.log("STATE OF orgFilter at getObs()", this.state.orgFilter)
    //console.log("STATE OF chosenObserver at getObs()", this.state.chosenObserver)
    //console.log("STATE OF chosenObservation at getObs()", this.state.chosenObservation)
    //console.log('STATE OF chosenAb', this.state.chosenAb)

    // before the request is sent; check the connection
    axios.interceptors.request.use(config => {
    //console.log('Request was sent from getObs()');
    // console.log(document.cookie.indexOf('csrftoken'))
    // console.log('csrftoken: ', csrftoken)
    return config;
    }, error => {
        return Promise.reject(error);
    });

    // URLS: select the appropriate URL to call in the get request
    let address = [];

    // if all observations are being sought, then orGFilter will be null
    if (this.state.orgFilter == null) {

      // if only the observations of a specific observer are being sought
        if (this.state.chosenObserver){
          //alert("Got to select an observer")
            address = API_URL + '?observer__in=' + (this.state.chosenObserver).toString()
            console.log('URL for selected observer: ', address);

        // if observations are being sought for a selection of id
        } else if (this.state.chosenObservation) {
            address = API_URL + '?id__in=' + (this.state.chosenObservation)
            console.log('URL for selected id: ', address);

        // if all observations are being sought
        } else {
            address = API_URL;
            //console.log('URL: ', address);
        }

    // filter observations by idOrg number to get the selected organisms
    } else {
        // if observations are being sought for only the occurrences of selected organism(s) where these are abundant
        if (this.state.chosenAb) {
            this.setState({chosenAb: false});
            address = API_URL + '?organism__in=' + (this.state.orgFilter).toString() + '&abundance__in=4,5' ;
            console.log('URL for selected spp & abundance: ', address);
          } 

        // if observations are being sought for only where the species is present i.e. excluding all observations of its absence from a location)  
        else if (this.state.chosenPr){
           this.setState({chosenPr: false});
           address = API_URL + '?organism__in=' + (this.state.orgFilter).toString() + '&abundance__in=1,2,3,4,5' ;
           console.log('URL for selected spp & presence: ', address);
        }
        
        else  {
            // URL for searching for any organism in an array of idOrg numbers
            address = API_URL + '?organism__in=' + (this.state.orgFilter).toString()
            console.log('URL: ', address);
        }
  }

    //OBSERVATIONS: get observations from the database
    axios.get(address)
         .then((response) => {
              let obsList = response.data.results ;
              //console.log( 'obsList: ', obsList);
              let results = Object.keys(obsList).map((key) => [Number(key), obsList[key]]);
              //console.log('Results of obs:', results)
              //KEEP THIS DATE FORMAT MM/dd/yyyy - not sure why though but it works
              results.forEach(function (item, index) {
                item[1].display_date = (format(new Date(item[1].date), 'MM/dd/yyyy'));
              });
         //console.log('Results of obs:', results);
         this.setState(() => {
              return {
                observations: results,
                loaded:true,
                reload: !this.state.reload
              };
         }//  OPTION TO PUT A CALLBACK FUNCTION HERE : something I want to happen after state is updated with the new/edited observations
         );
         console.log('updated observations', this.state.observations);
        }, (error) => {
          console.log(error);
         });
  }


  //get list of organisms from the database, to populate the dropdown selection in the form
  getOrganisms = () => {
    axios.get(API_URL2)
        .then(response => {
          let organisms = response.data.results
          let results = Object.keys(organisms).map((key) => [Number(key), organisms[key]]);
          //console.log('Results of organisms:', results)
          this.setState(() => {
            return {
              dropdownItems: results,
            };
        })
      }, (error) => {
      console.log(error);
      });
  }

  //get list of ids from the database, to populate the dropdown selection in the form
  getIds = () => {
    axios.get(API_URL)
        .then(response => {
          let results = response.data.results
          let observations = Object.keys(results).map((key) => [Number(key), results[key]]);
          //get all the ids out of those observations
          let ids = [], a;
          for (let i = 0, len = observations.length; i < len; i++) {
            a = observations[i][1].id
            ids.push(a);
         }
          this.setState(() => {
            return {
              dropdownObs: ids,
            };
        })
      }, (error) => {
      console.log(error);
      });
  }

  //get list of observers from the database, to use to populate the dropdown selection in the Observers form
  getObservers = () => {
    // get all observations
    axios.get(API_URL)
        .then(response => {
          let results = response.data.results
          let observations = Object.keys(results).map((key) => [Number(key), results[key]]);
          //get all the observers out of those observations
          let observers = [], a;
          for (let i = 0, len = observations.length; i < len; i++) {
             a = observations[i][1].observer
             observers.push(a);
          }
          // make the list of observers contain only unique values
          let unique = observers.filter(onlyUnique);
          //console.log('UNIQUE OBSERVERS: ', unique)
          this.setState(() => {
            return {
              dropdownItems2: unique,
            };
        })
      }, (error) => {
      console.log(error);
      });
   }

  // if a user is logged in, get their superuser status (a boolean)
  getUserStatus = () => {
    axios.get('username/' )
      .then((response) => {
       let superuser = response.data;
       //console.log('Superuser status:', superuser)
       this.setState({
            superuser: superuser,
      });
    }, (error) => {
      console.log(error.response);
    });
  }

  // handle the Clear all Filter's button by resetting the value of OrgFilter etc to null, and then calling the function to get all observations
  handleButton(event) {
    this.setState({
        orgFilter: null,
        chosenObserver: null,
        chosenObservation: null
      }, () => {this.getObs();} );
  }

  // handle change in the Organisms form's selections from the list of organisms: add the selections into 'value'
  handleChange = (event) => {
    this.setState({chosenAb: false, value: event.option});
    console.log('SELECTION:', Array.from(event.target.selectedOptions, (item) => item.value))
    this.setState({value: Array.from(event.target.selectedOptions, (item) => item.value)});
  }

  // handle change in the Observer form's selections from the list of observers: set the selection into 'value2'
  handleChange2 = (event) => {
    console.log('SELECTED OBSERVER', event.target.value);
    this.setState({valueOrg: event.target.value});
  }

  // handle change in the Id filter form's selections from the list of ids:
  handleChange3 = (event) => {
    this.setState({value: event.option});
    console.log('SELECTED Ids:', Array.from(event.target.selectedOptions, (item) => item.value))
    this.setState({idObs: Array.from(event.target.selectedOptions, (item) => item.value)});
  }

  handleId = (e) => {
    console.log(e.target.id);
    console.log(e.currentTarget.id);
    console.log((e.currentTarget.id === 'button2'), 'for button2')
    console.log((e.currentTarget.id === 'button3'), 'for button3')
    if (e.currentTarget.id === 'button2') {this.setState({chosenAb: true, chosenPr: false}, () => this.handleSubmit(e))}
    if (e.currentTarget.id === 'button3') {this.setState({chosenPr: true, chosenAb: false}, () => this.handleSubmit(e))}

  }

  // get the idOrg numbers of the organisms selected in the form and save them in the array named orgFilter
  handleSubmit(event) {
    console.log("SELECTED ORGANISMS", this.state.value)
    event.preventDefault();
    let arry = this.state.value
    let opts = [], optNo;
    //get just the id numbers of the selected items (the first value)
    for (let i = 0, len = arry.length; i < len; i++) {
      optNo = arry[i].split(":")[0]
      opts.push(optNo);
    }
    // put the selections into an array as alternating keys and values
    const list = Object.entries(opts)
    let arr = []
    for (let i = 0; i < list.length; i++) {
       arr.push(...list[i])
    }
    // select every second value starting with the first one - this removes the keys and just leaves an array of values (id numbers)
    let wanted = []
    for (let i = 1; i< arr.length; i=i+2) {
        wanted.push(arr[i]);
    }
    //console.log('wanted', wanted)  // This sets the value of orgFilter to an array of the id numbers of the organisms selected in the form
    this.setState({orgFilter: wanted}, () => {this.getObs();} );
  }

  //handle the Submit button on the Filter by Observer form
  handleSubmit2(event) {
    console.log("SELECTED OBSERVER", this.state.valueOrg)
    event.preventDefault();
    this.setState({chosenObserver: this.state.valueOrg, orgFilter: null }, () => {this.getObs();} );
  }

  // handle the Submit button on the Filter by ID form
  handleSubmit3(event) {
    console.log("SELECTED ID", this.state.idObs)
    event.preventDefault();
    let arry = this.state.idObs
    let opts = [], optNo;
    // get the selections
    for (let i = 0, len = arry.length; i < len; i++) {
      optNo = arry[i]
      opts.push(optNo);
    }
    // put the selections into an array as alternating keys and values
    const list = Object.entries(opts)
    let arr = []
    for (let i = 0; i < list.length; i++) {
       arr.push(...list[i])
    }
    // select every second value starting with the first one - this removes the keys and just leaves an array of values (id numbers)
    let wanted = []
    for (let i = 1; i< arr.length; i=i+2) {
        wanted.push(arr[i]);
    }
    this.setState({chosenObservation: wanted,  orgFilter: null }, () => {this.getObs();} );
  }


  defaultIfEmpty = value => {
    return value === "" ? "" : value;
  };

  render() {
    //console.log('superuser:', superuser)
    const dropdownItems = this.state.dropdownItems;
    const dropdownItems2 = this.state.dropdownItems2;
    const dropdownObs = this.state.dropdownObs;
    //console.log("List of observers: ", dropdownItems2)
    const superuser = this.state.superuser;
    const observations = this.state.observations;
    //console.log('this.state.orgFilter', this.state.orgFilter)
    // boolean and button for the cursor-functionality button
    const marker = this.state.marker;
    let button = <Button
                        onClick={this.handleClick}
                        color="primary"
                        size="sm"
                        style={{ minWidth: "80px", marginBottom: "-2px", marginTop: "2px", marginLeft: "6px" }}
                >{this.state.marker ? "Use cursor to create observation" : "Use cursor to get lat/lon"} 
                </Button>;

    return (
      <div className="App" id="outer-container">

        <div className='splitScreen' >

          <div className='leftPane'>
            <div className = 'leftPaneContents'>
              <div className = 'title'>
                <h3>Southern Seashores </h3>
                <h5>Kelp observations </h5>
                <hr></hr>
              </div>

              <div className='DisplayButton'>
                <h7><i>Click icon at far top right to open panel of observations.</i></h7><br/>
                <h7><i>The map can be dragged, even with the RH panel open.</i></h7><br/>

                {/* show button for cursor functionality : only shows if a superuser is logged in */}
                {superuser && (superuser[0] === true) ?  (button): (<h7>Double click locality on map to see its lat/long. <br/>Click map pin to find its id and lat/long</h7> )}
                
                <hr></hr>
              </div>

              <div className='filterForm'>
                <div className = 'speciesForm'>
                  
                  <Button
                        onClick={this.handleButton}
                        color="success"
                        size="sm"
                        className="clearFilterBtn"
                        style={{ minWidth: "80px", marginBottom: "2px", marginTop: "2px" }}
                  >Show all observations (map & panel)
                  </Button>
                  
                  <Label><h7>To select multiple items, hold down 'Ctrl' key</h7></Label>
                  
                  <p><strong>Filter by species</strong></p>

                  <Form onSubmit={this.handleSubmit} >
                    
                    <Input
                      type="select" multiple={true}
                      name="organisms"
                      id="organisms"
                      onChange={(event) => {this.handleChange(event);}}
                    >
                      {dropdownItems.map((item)=><option value={item[1].value} key={item[0]}>{item[1].idOrg}: {item[1].genus} {item[1].sp} {item[1].variety}</option>)}
                    </Input>
                                        
                    <Button
                      name = 'button1'
                      color="primary"
                      size='sm'
                      type="submit"
                      style={{ minWidth: "40px", marginBottom: "2px", marginTop: "8px"}}
                    >All records </Button>
                    <br></br>
                    
                    <div style={{ display: 'inline'}}>
                      <Button
                        color="primary"
                        size='sm'
                        type="submit"
                        name='button3'
                        id="button3"
                        onClick={this.handleId} 
                        style={{ minWidth: "40px", display: 'inline', marginBottom: "2px", marginTop: "8px", marginRight:'10px'}}
                      >Where present</Button>
                      
                      <Button
                        color="primary"
                        size='sm'
                        type="submit"
                        name='button2'
                        id="button2"
                        onClick={this.handleId}
                        style={{ minWidth: "40px", display: 'inline', marginBottom: "2px", marginTop: "8px"}}
                      >Where abundant</Button>

                    </div>
                  </Form>

                  <hr></hr>
              </div>

                <div className = 'iDForm'>
                  <p><strong>Find observations by Id number</strong></p>

                  <Form onSubmit={this.handleSubmit3} >
                      {/* <Label for="observation">Observation:</Label> */}
                      <Input
                        type="select" multiple={true}
                        name="ids"
                        id="ids"
                        //  value={this.defaultIfEmpty(this.state.idObs)}
                        onChange={(event) => {this.handleChange3(event);}}
                      >
                        {/* <option value="">Select Id of  observation to display</option>  */}
                        {dropdownObs.map((item)=><option value={item.value} key={item}>{item} </option>)}
                      </Input>

                      <Button
                        // DO NOT USE 'type = button' on this Submit button
                        color="primary"
                        size='sm'
                        style={{ minWidth: "80px", marginBottom: "2px", marginTop: "12px" }}
                      >Submit</Button>
                    </Form>
                    <hr></hr>
                </div>

                <div className='observerForm'>
                  {/* <p><strong>Filter by observer</strong></p> */}

                  <Form onSubmit={this.handleSubmit2} >
                    {/* <Label for="observer">Observer:</Label> */}
                    <Input
                      type="select"
                      name="select"
                      id="observer"
                      value={this.defaultIfEmpty(this.state.value2)}
                      onChange={(event) => {this.handleChange2(event);}}
                    >
                      <option value="">Select observations by observer</option>
                      {dropdownItems2.map((item)=><option value={item.value} key={item}>{item} </option>)}
                    </Input>

                    <Button
                      // DO NOT USE 'type = button' on this Submit button
                      color="primary"
                      size='sm'
                      style={{ minWidth: "80px", marginBottom: "2px", marginTop: "12px" }}
                    >Submit</Button>
                  </Form>
                  <hr></hr>
                </div>

                </div>

                <div>
                  <Legend/>
                </div>

              </div>
          </div>

          <div className='rightPane'>
            <Fragment  >

                  <Sidebar
                    pageWrapId={'page-wrap'}
                    outerContainerId={'splitScreen'}
                    observations = {this.state.observations}
                    resetState={this.resetState}
                    superuser={superuser}
                  />

                      <div id="page-wrap">
                        {/* Could put something (e.g. a title or /navigation Links) above the map; if so, add padding to page-wrap in index.css */}
                      </div>


                {/* <Header />  */}
                {/* The header has nothing in it at this stage   */}

                <MyMap
                    zoom={9}
                    center={{ lat: -46.769, lng: 168.521 }}
                    observations = {observations}
                    superuser = {superuser}
                    getIds = {this.getIds}
                    getObs = {this.getObs}
                    marker = {marker}
                />
            </Fragment>

          </div>
        </div>

    </div>

   );
 }
}

export default App;
//_________________________________________________________________________________________________________

// legend for the map (on teh LH panel) , showing the abundance codes and colours
function Legend() {

  return (
    <Table className='legendTable'>
      <thead>
        <tr>
          <th>Marker color</th>
          <th>Code</th>
          <th>Quantity    (abundance)</th>
        </tr>
      </thead>

      <tbody>

      <tr>
        <td><span className="circle" id='circle0'></span></td>
        <td>0</td>
        <td>absent</td>
      </tr>
      <tr>
        <td><span className="circle" id='circle1'></span></td>
        <td>1</td>
        <td>sparse</td>
      </tr>
      <tr>
        <td><span className="circle" id='circle2'></span></td>
        <td>2</td>
        <td>uncommon</td>
      </tr>
      <tr>
        <td><span className="circle" id='circle3'></span></td>
        <td>3</td>
        <td>common</td>
      </tr>
      <tr>
        <td ><span className="circle" id='circle4'></span></td>
        <td>4</td>
        <td>abundant: localised bed</td>
      </tr>
      <tr>
        <td ><span className="circle" id='circle5'></span></td>
        <td>5</td>
        <td>abundant: zone forming</td>
      </tr>

      </tbody>
    </Table>
  )
}

//__________________________________________________________________________________________________________

// Function that changes an array so it contains only unique values [from Stack Overflow 1960473]
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

