/* Contains the Modal2 Component : 
the modal set up to contain the form for creating an observation with pre-loaded latitude and longitude 
*/

import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import MarkerForm from "./FormForMarker"; 
import '../index.css'
//import {UserContext} from './Context';

class Modal2 extends Component {
  //static contextType = UserContext;
  state = {
    modal: false
  };
  
  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

 componentDidMount() {
    console.log("PROPS IN Modal2", this.props);
    this.toggle();
 }

 componentDidUpdate(prevProps){
    // get latest lats and longs (from a click on the map), as the props update
    if (this.props.lat !== prevProps.lat){
        if ((this.props.lat) ) {                        // 0.1 is the default in AddClick on marker - avoids opening modal if there's been no click
            this.setState((previous) => {
                return {
                  modal: !previous.modal, 
                  }; 
              });
        }
    }
  }
  
    
  render() {
    //Props sent from AddMarkerOnClick component
    console.log("PROPS IN Modal2", this.props);
    
    return (
      
        <Modal 
            modalClassName="modal-dialog"
            isOpen={this.state.modal} 
            contentClassName="custom-modal-style"
            backdrop="static"
            animation="false"
            centered={false}
            size="sm"
            style={{ marginleft:"-100px"}}
        >
          <ModalHeader 
              toggle={this.toggle}>{'Create Observation'}
          </ModalHeader>

          <ModalBody >
            <MarkerForm
              toggle={this.toggle}
              lat = {this.props.lat}
              lon = {this.props.lon}
              getIds = {this.props.getIds}
              getObs = {this.props.getObs}
            />
          </ModalBody>
        
        </Modal>
        
    );
  }
}

export default Modal2;
