/* Contains the Sidebar ocomponent for the right hand side of the map - it is transparent and slides over the map (see CSS)
   Renders the RH panel: menu with a container for a list of observations (Obslist) and buttons (NewObsModal - the buttons are part of the latter)
   Also contains a carousel of images, items for which are selectable by buttons.
   */

import { reveal as Menu } from 'react-burger-menu';
import '../Sidebar.css';
import React, { Component } from "react";
import axios from "axios";
import { Col, Container, Row , Carousel, CarouselItem, CarouselCaption, CarouselIndicators, CarouselControl, Button, ButtonGroup } from "reactstrap";
import ObsList from "./ObsList";
import ObsModal from "./ObsModal";

axios.defaults.withCredentials = true

//Right hand panel; gets observations from database and passes them to child (Home) as props
class Sidebar extends Component {
  constructor(props){
    super(props)
    this.state = {
      items:[],
    };
    this.handleBtn = this.handleBtn.bind(this);
  };

  componentDidMount() {
    //console.log("PROPS in Sidebar: ", this.props)
  }

  handleBtn = (e) => {
    //Declare arrays of images (stored in the public folder) to be sent as props to the carousel
    const items1 = [
      // TODO: use captionTexts if desired

      {
        src: './img/D_willana_P1020119.jpg',
        altText: 'Slide 1 cannot display',
        caption: 'Durvillaea willana',
        captionText: 'Halfmoon Bay, Nth side, Stewart Island',
        header: 'Slide 1 Header',
        key: '11'
      },
      {
        src: './img/D_willana_IMG_1893.jpg',
        altText: 'Slide 2 cannot display',
        caption: 'Durvillaea willana',
        captionText: 'Green Islets, Fiordland',
        header: 'Slide 2 Header',
        key: '12'
      },
      {
        src: './img/D_willana_holdfasts_from_publ.jpg',
        altText: 'Slide 3 cannot display',
        caption: 'Durvillaea willana holdfasts',
        captionText: 'Moses Nugget, Ringaringa, Stewart Island',
        header: 'Slide 3 Header',
        key: '13'
      },
      {
        src: './img/D_willana_uplifted_photo2.jpg',
        altText: 'Slide 4 cannot display',
        caption: 'uplifted Durvillaea willana, north of Kaikoura, 2017',
        captionText: 'Photographed by Elizabeth Lochhead',
        header: 'Slide 4 Header',
        key: '14'
      },
    ];

    const items2 = [
      {
        src: './img/D_antarctica_Ruapuke_Sth_end_IMG3345.jpg',
        altText: 'Slide 1 cannot display',
        caption: ' Durvillaea antarctica',
        header: 'Slide 1 Header',
        captionText: 'South end of Ruapuke Island, eastern Foveaux Strait',
        key: '21'
      },
      {
        src: './img/D_antarctica_P1000060.jpg',
        altText: 'Slide 2 cannot display',
        caption: 'Durvillaea antarctica',
        captionText: 'Big Hellfire, Stewart Island',
        header: 'Slide 2 Header',
        key: '22'
      },
      {
        src: './img/D_antarctica_P1000054.jpg',
        altText: 'Slide 3 cannot display',
        caption: 'Durvillaea antarctica',
        captionText: 'Big Hellfire, Stewart Island',
        header: 'Slide 3 Header',
        key: '23'
      },
      {
        src: './img/D_antarctica_P1000059.jpg',
        altText: 'Slide 4 cannot display',
        caption: 'Durvillaea antarctica',
        captionText: 'Big Hellfire, Stewart Island',
        header: 'Slide 4 Header',
        key: '24'
      },
    ];

    const items3 = [
      {
        src: './img/D_poha_StIs_P1020072.jpg',
        altText: 'Slide 1 cannot display',
        caption: ' Durvillaea poha',
        captionText: 'Horsehoe Bay (SE side), Stewart Island',
        header: 'Slide 1 Header',
        key: '31'
      },
      {
        src: './img/D_poha_StIs_P1020076.jpg',
        altText: 'Slide 2 cannot display',
        caption: 'Durvillaea poha',
        captionText: 'Horsehoe Bay (SE side), Stewart Island',
        header: 'Slide 2 Header',
        key: '32'
      },
      {
        src: './img/D_poha_StIs_P1020077.jpg',
        altText: 'Slide 3 cannot display',
        caption: 'Durvillaea poha',
        captionText: 'Horsehoe Bay (SE side), Stewart Island',
        header: 'Slide 3 Header',
        key: '33'
      },
      {
        src: './img/D_poha_StIs_P1020137.jpg',
        altText: 'Slide 4 cannot display',
        caption: 'Durvillaea poha',
        captionText: 'Halfmoon Bay (SE side), Stewart Island',
        header: 'Slide 4 Header',
        key: '34'
      }
    ];

    console.log(e.currentTarget.id);
    if (e.currentTarget.id === 'DwillanaBtn') {this.setState({items: items1})};
    if (e.currentTarget.id === 'DantacticaBtn') {this.setState({items: items2})};
    if (e.currentTarget.id === 'DpohaBtn') {this.setState({items: items3})};
  }

  render(){
    let items = this.state.items;
    let superuser = this.props.superuser;
    //console.log(superuser && (superuser === true))

    if (!this.props.observations) {
          return null;
    }

    return (

      <Menu right width={ 760 } noOverlay>

        {/* //  container for button and table of observations */}
        <Container
            style={{ marginTop: "0px", marginBottom: "0px", className: "obsTable", width:'780'}}
        >
          <p style={{lineHeight:'0.8', marginBottom: "5px"}}><em><i>This panel displays a list of all observations or, if a subset has been selected in the left hand panel, that subset.</i></em></p>
          <p style={{lineHeight:'0.8', marginBottom: "5px"}}><em><i>Scroll to the bottom to see photos of the main species recorded on this website.</i></em></p>
          <p style={{lineHeight:'0.8'}}><em><i>To close the panel, either click the X at right or use the Esc key.</i></em></p>
          <br></br>
          {superuser && (superuser === true) ?
          <h2 style={{ color:"blue", marginBottom: "10px", textAlign: "center" }}>--- Observations  ---</h2>
          : <span><br></br></span> }

            {/* Space for the button that is titled 'Create New Observation' */}
            <Row>
              <Col>
                <ObsModal
                    create={true}
                    resetState={this.props.resetState}
                    superuser={superuser}
                />
              </Col>
            </Row>


            <Row>
              <Col>
                <ObsList
                  observations = {this.props.observations}
                  resetState={this.props.resetState}
                  superuser={superuser}
                />
              </Col>
            </Row>

            {/* Duplicate button if required
            <Row>
              <Col>
                <NewObsModal create={true} resetState={this.resetState} />
              </Col>
            </Row> */}
        </Container>
          {/* <a className="menu-item" href="/another_page">  </a> // How to link to another page - if I ever need to do this*/}
          <div className = 'btnInstruction' style= {{ marginTop: "-2px", marginBottom: "0px" }}>

              {/* Buttons to select the array of images that will display in the SeaweedCarousel according to the button selected*/}
              <ButtonGroup onSubmit={this.handleSubmit} className = 'seaweed_images'>

                  <Button name='DwillanaBtn'
                          id="DwillanaBtn"
                          onClick={this.handleBtn}
                  >D. willana  </Button>

                  <Button name='DantacticaBtn'
                          id="DantacticaBtn"
                          onClick={this.handleBtn}
                  >D. antarctica  </Button>

                  <Button name='DpohaBtn'
                          id="DpohaBtn"
                          onClick={this.handleBtn}
                  >D. poha</Button>

              </ButtonGroup>

              {/* Carousel of images  */}
              <SeaweedCarousel
                items = {items}
              />
        </div>
      </Menu>

  );
  }
}
export default Sidebar

/* NOTE: the menu opens from the left by default. To have it open from the right, use the right prop. It's just a boolean so you don't need to specify a value.
Then set the position of the button using CSS.
<Menu right /> */

//________________________________________________________________________________________________________________________

//Display a carousel of images sent as props from Sidebar Component
class SeaweedCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    }
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  componentDidMount() {
    //console.log("PROPS IN Carousel", this.props);
  }

  componentDidUpdate(prevProps){
    // as latest items arrive, props update, => set activeIndex back to zero again
    if (this.props.items !== prevProps.items){
        if ((this.props.items) ) {
          this.setState(() => {
            return {
              activeIndex: 0,
            };
          }, () => {this.next();});
        }
    }
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    let items = this.props.items;
    if (this.animating) return;
    let nextIndex = this.state.activeIndex === (Object.values(items)).length - 1 ? 0 : this.state.activeIndex + 1;
    console.log("nextIndex", nextIndex)
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    let items = this.props.items;
    if (this.animating) return;
    let nextIndex = this.state.activeIndex === 0 ? (Object.values(items)).length - 1 : this.state.activeIndex - 1;
    console.log("nextIndex", nextIndex)
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    let slides;
    const { activeIndex } = this.state;
    let items = this.props.items;
    items = Object.values(items);

    // if items is undefined (i.e. until the props items reach render), load this image & instruction in the carousel
    if (items.length <= 0) {
      // TRY: {src: process.env.PUBLIC_URL + "/img/D_poha_StIs_P1020077.jpg",
      items = [
        {src: './img/D_poha_StIs_P1020077.jpg',
          altText: 'Slide 1 cannot display',
          caption: 'Please use the buttons to select a species and display photos',
          captionText: 'Then use the arrows to click left or right',
          header: 'Slide 1 Header',
          key: '11'
        }
      ];

    }

    slides = items.map((item) => {
        return (
          <CarouselItem
            className="carouselImg"
            onExiting={this.onExiting}
            onExited={this.onExited}
            key={item.src}
          >
            <img src={item.src} alt={item.altText} />
            <CarouselCaption captionHeader={item.caption} captionText={item.captionText} />
          </CarouselItem>
        );
      })

    return (
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
        // stop the carousel from auto-playing
        interval={false}
      >
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
      </Carousel>
    );



  }



}


