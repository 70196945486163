/* Contains the ObsForm Component : the form for creating or editing a new observation */

import React from "react";
import { Button, Form, FormGroup, Input, Label} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { API_URL,  API_URL2 } from "../constants";
import moment from 'moment'

axios.defaults.withCredentials = true

class ObsForm extends React.Component {
  constructor(props){
    super(props)
    this.state = {
        idOrg: 0,
        orgName: '0: x x',
        organism: 0,
        id:0,
        date: moment().locale('en-nz').format('l'),                    // note, this is the date in std NZ format 
        sitename: "",
        lat: null,
        lon: null,
        abundance: 0,
        note: "",
        observer: "CHH",
        recorder: 1,
        imageURL1:'',
        dropdownItems:[],                    // List of organisms in the database
        selectedvalue:'CHOOSE ONE',          
        color: 'white',
        reload:false,
        loaded:false
    };
    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }  
    
  componentDidMount() {
    console.log("PROPS", this.props)
    // if there is an observation prop sent from NewObsModal for editing an observation, save it into state 
    if (this.props.observation) {
      const { id, date, organism, sitename, lat, lon, abundance, note, observer, recorder } = this.props.observation;
      this.setState(() => { return {id, date, organism, sitename, lat, lon, abundance, note, observer, recorder };});
      this.setState(() => { return { selectedvalue: organism.idOrg}});
    }
    
    //get list of organisms from the database, & populate the dropdown selection in the form  
    axios.interceptors.request.use(config => {
        // before the request is sent; check the connection
        console.log('Request was sent');
        // console.log(document.cookie.indexOf('csrftoken'))
        // console.log('csrftoken: ', csrftoken) 
        return config;
      }, error => {
          return Promise.reject(error);
      });

    axios.get(API_URL2, {headers: {'XCSRF-TOKEN': csrftoken }})
         .then(response => {
            //get all organisms
            let organisms = response.data.results
            var results = Object.keys(organisms).map((key) => [Number(key), organisms[key]]);
            this.setState(() => {
              return {
                dropdownItems: results, 
              }; 
          })
      }, (error) => {
      console.log(error);
      });
  }
         
  // handle change in the selections from the dropdown list of organisms in the form: get the corresponding orgamism from d/base
  handleChange = (e) => {
    let new_id = (e.target.value).split(":")[0];
    //use setState callback to ensure state is set before getOrganism() is called         
    this.setState({orgName : e.target.value, idOrg: new_id}, () => {this.getOrganism (new_id);} );  
  };  
     
  // handle change in other entries on the form  (ie everything except the organism)      
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value});
  }; 
  
  // create a new observation and save it in the database (to display on render)
  createObservation = (e) => {
    e.preventDefault();
    try {
      this.postObs()
    }
    catch (error) {console.log(error);}
  }
   
  postObs = () => axios.post(API_URL, {
                        'organism': this.state.idOrg,
                        'date': moment(this.state.date).locale('en').format('l'),   //note, this is the date in std US format 
                        //'date': this.state.date,  
                        'sitename': this.state.sitename,
                        'lat': this.state.lat,
                        'lon': this.state.lon,
                        'abundance': this.state.abundance,
                        'note': this.state.note,
                        'observer': this.state.observer,
                        'recorder': this.state.recorder,
                        'imageURL1': this.state.imageURL1
                      },
                      {
                        headers: {'XCSRF-TOKEN': csrftoken }, 
                        'Content-Type': 'application/json',
                      })
                      .then (() => {
                          //console.log(document.cookie.indexOf('csrftoken'))
                          //console.log('csrftoken: ', csrftoken)

                          // refresh the table
                          this.props.resetState();
                          // close the modal
                          this.props.toggle();
                      })  
                      .catch(error => {
                        console.log(error);
                      });

editObservation = e => {
  e.preventDefault();
  try {
    this.editObs()
  }
  catch (error) {console.log(error);}
  }
  
                     
// edit an existing observation
  editObs = () => 
                  axios.patch(API_URL + this.state.id + '/', {
                          'organism': this.state.organism.idOrg,  
                          'date': this.state.date,                                 //note, this is the date in std format not the display date
                          'sitename': this.state.sitename,
                          'lat': this.state.lat,
                          'lon': this.state.lon,
                          'abundance': this.state.abundance,
                          'note': this.state.note,
                          'observer': this.state.observer,
                          'recorder': this.state.recorder,
                          'imageURL1': this.state.imageURL1
                        } ,
                        {
                          headers: 
                           {'XCSRF-TOKEN': csrftoken }, 
                           'Content-Type': 'application/json',  
                        }
                        )
                        .then (() => {
                          //console.log(document.cookie.indexOf('csrftoken'))
                          //console.log('csrftoken: ', csrftoken) 

                          // refresh the table
                          this.props.resetState();  
                            
                          // close the modal
                          this.props.toggle();
                            
                        })
                        .catch(error => {
                          console.log(error);
                        });


   // retrieve the organism of an existing observation (based on the id of the organism selected in the table's dropdwon list)
   getOrganism = (iD) => axios.get('API_URL2 + iD')                 
                              .then((response) => {
                                    let organism = response.data;
                                    //console.log('organism: ', organism);
                                    this.setState(() => {
                                      return {
                                        organism: organism
                                        }; 
                                    });
                                    
                                    console.log("this.state.organism & selectedValue: ", this.state.organism)  
                                })  
                              .catch(error => {
                                  console.log(error);
                                });
                            
      
  defaultIfEmpty = value => {
    return value === "" ? "" : value;
  };                 
  
  
    
  render() {
    const dropdownItems = this.state.dropdownItems;
    const selectedvalue = this.state.selectedvalue;
    console.log('selectedvalue: ',  selectedvalue)  
    
    if (!dropdownItems) {
      return <div />
    }  

    //render form for creating or editing an observation (appears inside modal)
    return (
          <Form onSubmit={this.props.observation ? this.editObservation : this.createObservation } >
            <FormGroup>
              <Label for="date">Date:</Label>
              <Input valid
                type="Date"
                name="date"
                onChange={this.onChange}
                value={this.defaultIfEmpty(this.state.date)}
              />
            </FormGroup>

            <FormGroup>
              <Label for="organism">Organism:</Label>
              <Input valid
                type="select"
                name="organism"
                id="organism"
                onChange={this.handleChange} 
              >                                          
                <option value="">{this.state.selectedvalue}</option> 
                {dropdownItems.map((item)=><option value={item[1].value} key={item[0]}>{item[1].idOrg}: {item[1].genus} {item[1].sp} {item[1].variety}</option>)}
              </Input>
            </FormGroup>
            
            <FormGroup>
              <Label for="sitename">Sitename:</Label>
              <Input valid
                type="text"
                name="sitename"
                onChange={this.onChange}
                value={this.defaultIfEmpty(this.state.sitename)}
              />
            </FormGroup>

            <FormGroup>
              <Label for="lat">Lat:</Label>
              <Input valid
                type="Number"
                name="lat"
                onChange={this.onChange}
                value={this.defaultIfEmpty(this.state.lat)}
              />
            </FormGroup>

            <FormGroup>
              <Label for="lon">Lon:</Label>
              <Input valid
                type="Number" 
                name="lon"
                onChange={this.onChange}
                value={this.defaultIfEmpty(this.state.lon)}
              />
              {/* <FormFeedback valid>looks good</FormFeedback> */}
            </FormGroup>
            
            <FormGroup>
              <Label for="abundance">Quantity:</Label>
              <Input valid
                type="select" 
                name="abundance"  
                id="abundance"    
                onChange={this.onChange}
                value={this.defaultIfEmpty(this.state.abundance)}                          
               >
                <option value="" >Choose</option>
                <option value='0'>0: absent</option>
                <option value='1'>1: sparse</option>
                <option value='2'>2: uncommon</option>
                <option value='3'>3: common</option>
                <option value='4'>4: abundant, localised bed</option>
                <option value='5'>5: abundant, zone-forming</option>
                
                </Input>
            </FormGroup>
            
            <FormGroup>
              <Label for="note">Note:</Label>
              <Input valid
                type="textarea"
                name="note"
                onChange={this.onChange}
                value={this.defaultIfEmpty(this.state.note)}
              />
              
            </FormGroup>
            
            <FormGroup>
              <Label for="observer">Observer:</Label>
              <Input valid
                type="text"
                name="observer"
                onChange={this.onChange}
                value={this.defaultIfEmpty(this.state.observer)}
              />
            </FormGroup>
            
            <FormGroup>
              <Label for="recorder">Recorder:</Label>
              <Input valid
                type="text"
                name="recorder"
                onChange={this.onChange}
                value={this.defaultIfEmpty(this.state.recorder)}
              />
            </FormGroup>
            
            <FormGroup>
              <Label for="imageURL1">ImageURL1:</Label>
              <Input valid
                type="text"
                name="imageURL1"
                onChange={this.onChange}
                value={this.defaultIfEmpty(this.state.imageURL1)}
              />
            </FormGroup>
    
            <Button
                color="primary"
                className="float-right"
                style={{ minWidth: "80px", marginBottom: "12px", marginTop: "12px" }}
            >Send</Button> 
        
          </Form>
        );
      }
    
    }
export default ObsForm;

//------------------------

  
// Function to get a cookie for csrf token - code copied from Django documentation on CSRF protection
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          // If re-using code, check this this cookie string begins with the name I want
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
          }
      }
  }
  return cookieValue;
}
// call the function to get a cookie and store it in constant
const csrftoken = getCookie('csrftoken');