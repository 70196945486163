/* The ConfirmRemovalModal Component allows a user to do exactly that */

import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, Button, ModalFooter } from "reactstrap";
import axios from "axios";
import { API_URL } from "../constants";

class ConfirmRemoval extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      modal: false
      };
   this.toggle = this.toggle.bind(this);
  }

  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };
  
  deleteObservation = id => {
    //console.log(document.cookie.indexOf('csrftoken'))
    //console.log('csrftoken: ', csrftoken) 
    axios.delete(API_URL + id + '/', {
      headers:{'XCSRF-TOKEN': csrftoken }
    })
         .then(() => {
          this.props.resetState();
          this.toggle();  
        }
        , (error) => {
          console.log(error);
        });
  };

  render() {
    
    return (
      <Fragment>
        
        <Button 
            size="sm" 
            color="danger" 
            onClick={() => this.toggle()}
            style={{  marginBottom: "2px" }}
        >
          Delete
        </Button>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Confirm deletion?
          </ModalHeader>

          <ModalFooter>
            
            <Button type="button" onClick={() => this.toggle()}>
              Cancel
            </Button>

            <Button
              type="button"
              size="sm"
              color="primary"
              onClick={() => this.deleteObservation(this.props.id)} 
            >
              Yes
            </Button>

          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default ConfirmRemoval;
//------------------------

// Function to get a cookie for csrf token - code copied from Django documentation on CSRF protection
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          // If re-using code, check this this cookie string begins with the name I want
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
              break;
          }
      }
  }
  return cookieValue;
}
// call the function to get a cookie and store it in constant
const csrftoken = getCookie('csrftoken');