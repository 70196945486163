/* // Contains MyMap and Add Markers components: the map and the markers that show the location of all observations saved in the database */

import React, {useState, Component, Fragment} from "react";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, Tooltip, AttributionControl} from "react-leaflet";
import axios from "axios";
import ReactDOMServer from 'react-dom/server';
import Leaflet from 'leaflet'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import '../index.css';
import Modal2 from "./Modal2";
import { faMapPin} from '@fortawesome/free-solid-svg-icons'

library.add(faMapPin )
axios.defaults.withCredentials = true


//Display the map
class MyMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded:false
    };
  }

  componentDidMount() {
    //console.log('PROPS: ', this.props)
    this.setState({loaded:true});

  }

  shouldReload(){
    this.setState({reload:!this.state.reload});
  }

  render() {
    const observations = this.props.observations;
    // const superuser = this.props.superuser;
    // const marker = this.props.marker;
    
    // eslint-disable-next-line
    const api_key=process.env.REACT_APP_MAP_KEY;
    //console.log('MAP KEY', api_key)

    if (!this.state.loaded === true) {
      return <div />
    }
    return (

      <MapContainer
            center={this.props.center}
            zoom={this.props.zoom}
            maxZoom={22}
            attributionControl={false}
            zoomControl={true}
            doubleClickZoom={false}
            //scrollWheelZoom={true}
            dragging={true}
            animate={true}
            easeLinearity={0.35}
            style={{ height: "100vh" }}    // HEIGHT OF THE MAP
      >

      <TileLayer
          //add the LINZ Aerial Imagery Basemap in Web Mercator: see https://www.linz.govt.nz/data/linz-data/linz-basemaps/how-use-linz-basemaps-apis
          attribution='© <a href="//www.linz.govt.nz/linz-copyright">LINZ CC BY 4.0</a> © <a href="//www.linz.govt.nz/data/linz-data/linz-basemaps/data-attribution">Imagery Basemap contributors</a>'
          url="https://basemaps.linz.govt.nz/v1/tiles/aerial/EPSG:3857/{z}/{x}/{y}.webp?api=c01f9zj0mvxbys5rmvp9ane20x3"

      />
      <AttributionControl position="bottomleft" prefix={""} />
      {/* Add a marker, with a custom icon, for each observation */}
      <AddMarkers
          reloadParent={this.shouldReload.bind(this)}
          observations = {observations}
      />

      {/* Add a std marker when the map is clicked, to access/display lat and lon */}
      <MarkerOnClick
          // superuser = {superuser}
          marker = {this.props.marker}
          getIds = {this.props.getIds}
          getObs = {this.props.getObs}
      />

    </MapContainer >
    )}
}

export default MyMap;

//-----------------------------------------------------------------------------------------------------------------------------------------------

// Get lat and long of each observation from the database and mark them on the map */
class AddMarkers extends Component{
  constructor(props) {
    super(props);
    this.state = {
      colors:['white',],
      markers: [{
        lat: -46.7,
        lon: 168.6,}, ],
      loaded:false,
      reload:false
    };
    this.updateObs = this.updateObs.bind(this)
  }

  componentDidMount() {
    this.resetState();
  }

  resetState = () => {
    this.updateObs();
  };

  componentDidUpdate(prevProps){
    //console.log("UPDATED PROPS for markers: ",  this.props.observations, )
    // get updated observations, as the props change/update
    if (this.props.observations !== prevProps.observations){
       this.updateObs();
    }
  }

  updateObs() {
    // colour-code the markers based on their abundance number
    let obs = this.props.observations
    if (obs !== (null || undefined || '[]') ) {
      let position, ab, arry = [], colors=[]
      obs && obs.forEach(function (i, index) {
        ab = (i[1].abundance)
        position = [i[1].lat, i[1].lon, i[1].id]
        if (i[1].lat && ab) {
          arry.push(...[position])
          colors.push(...[ab])
          }
          });

      this.setState(() => {
        return {
          colors: colors,
          markers: arry,
        };
      })
    }
  }

  render(){
    const colors = this.state.colors;
    const markers =this.state.markers;

    return (
      <>
        {markers.map((marker, i) => (
          <Marker key={`marker-${i}-${colors[i]}${Date.now()}`} position={marker} icon={get_icon(colors[i])}
          >
              {/* display the id number, lat and long on the Popup of each observation (latter accessed by a click) */}
              <Popup>
                <span>
                {/* {marker[2]}: {JSON.stringify(marker, null, 2)}<br />  */}
                Id {marker[2]}: {marker[0]} , {marker[1]}<br />
                </span>
              </Popup>

          </Marker>
        ))}
      </>
    )
  }
}
//____________________________________________________________________________________________________________


// Function to allocate a icon to an observation according to its abundance (pass in color[i])
function get_icon(ab){

  let markerColor = 'white';
  // eslint-disable-next-line
  if (ab == 0) {
      markerColor= 'rgb(' + 182 + ',' + 182 + ',' +  182 + ')'}
  // eslint-disable-next-line
  else if (ab == 1){
        markerColor= 'rgb(' + 177 + ',' + 178 + ',' +  233 + ')'}
  // eslint-disable-next-line
  else if (ab == 2){
      markerColor= 'rgb(' + 242 + ',' + 201 + ',' +  2532 + ')'}
  // eslint-disable-next-line
  else if (ab == 3){
      markerColor=  'rgb(' + 250 + ',' + 217 + ',' +  6 + ')'}
  // eslint-disable-next-line
  else if (ab == 4){
      markerColor=  'rgb(' + 245 + ',' + 139 + ',' + 10 + ')'}

  else
      {markerColor = 'rgb(' + 252 + ',' + 68 + ',' +  13 + ')'}

  const iconHTML = ReactDOMServer.renderToString(<FontAwesomeIcon icon='map-pin' style={{color: markerColor}}/>)
  const customMarkerIcon = new Leaflet.DivIcon({html: iconHTML,});
  return customMarkerIcon
  }
//-----------------------------------------------------------------------------------------------------------------------------------------

// Function to add a marker when the map is clicked, displaying lat and lon;
// Allows a superuser to access a form pre-loaded with lat and lon, to create a new observation

function MarkerOnClick (props) {
  // let superuser = props.superuser
  let mark = props.marker
  //console.log('superuser in MarkerOnClick', superuser, '; marker', mark)
  const [position, setPosition] = useState(null);
  //console.log("position: ", position&& position.lat, position&& position.lng)

  useMapEvents({
    dblclick: (event) => {setPosition(event.latlng); console.log(event.latlng)},
  });

  return (
    // if position is null, do nothing
    position === null ? null : (
        
        <Fragment>
            {/* if mark == true, show marker and tooltip (but do NOT open the modal)           */}
            {mark === true ?
              <Marker position={position}  iconSize = '[25,41]' iconAnchor = '[12,41]' className = 'onclick-icon'>
                  <Tooltip direction="bottom" offset={[45, 45]} opacity={1} permanent>
                    <span>Lat: {parseFloat(JSON.stringify(position.lat)).toFixed(3)}, Lon: {parseFloat(JSON.stringify(position.lng)).toFixed(3)}</span>
                    {/* //TODO: use pre instead of span for square brackets TODO: FORMAT XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX*/}
                  </Tooltip>
            </Marker>:
            null}
        
            {/* if mark == false, open modal and do NOT show marker and tooltip  */}
            {/* (TODO: CHECK there's no need to ensure a superuser is logged in because default is to show marker) */}
            {/* {(superuser[Object.keys(superuser)[0]] === true)  ? */}
            {(mark === false)  ?
                <Modal2
                      lat = {position.lat}
                      lon = {position.lng}
                      toggle = {true}
                      getIds = {props.getIds}
                      getObs = {props.getObs}
                />  : null }
        </Fragment>

    )
  )
}
//------------------------------------------------------------------------------------------------------------------------


// Function to get a cookie for csrf token - code copied from Django documentation on CSRF protection
// function getCookie(name) {
//   let cookieValue = null;
//   if (document.cookie && document.cookie !== '') {
//       const cookies = document.cookie.split(';');
//       for (let i = 0; i < cookies.length; i++) {
//           const cookie = cookies[i].trim();
//           // If re-using code, check this this cookie string begins with the name I want
//           if (cookie.substring(0, name.length + 1) === (name + '=')) {
//               cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
//               break;
//           }
//       }
//   }
//   return cookieValue;
// }
// call the function to get a cookie and store it in constant
//const csrftoken = getCookie('csrftoken');
