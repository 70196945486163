/* Contains the ObsModal Component : the modal set up to contain the form (the latter being in a different component) and buttons for creating/editing/removing observations */

import React, { Component, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import ObsForm from "./Form"; 
import '../index.css'

class ObsModal extends Component {
  state = {
    modal: false,
  };
  
  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };
  

  render() {
    //Props sent from AddMarkerOnClick and Sidebar components
    //console.log("PROPS IN ObsModal.js: ", this.props);
    let superuser = this.props.superuser; 
    const create = this.props.create;

    // button for 'Edit'
    let title = "Editing observation";
    let button = <Button 
                      size="sm" 
                      color="primary"
                      onClick={this.toggle}
                      style={{ marginBottom: "10px" }}
                  >
                  Edit</Button>;               
    
    // if the value of 'create' is true, change the button to the Create New Observation one
    if (create) {
      title = "Create an observation";
      button = (
          <Button
            color="primary"
            onClick={this.toggle}
            style={{ marginRight:"20px", marginBottom: "10px" }}  //style of ( incl. space below) the button
          >
            Create New Observation               
          </Button> 
      );

    }
    
    return (
      
      <Fragment>
          {/* This next bit of code gets a value for superuser and checks whether it is true or false to determine whether to display buttons for editing or creating observations
              Ternary condition: This renders "Create new observation" & "Edit" buttons only if a superuser is logged in  i.e. if superuser == true    */}
              
                        { superuser && (superuser[0] === true) ?
                            (button)
                            : 
                            <span></span>
                        }
          
          <Modal 
              modalClassName="modal-dialog"
              isOpen={this.state.modal} 
              contentClassName="custom-modal-style"
              centered={false} 
              size="sm"
              style={{ marginleft:"-100px"}}
          >

            <ModalHeader 
                toggle={this.toggle}>{title}
            </ModalHeader>

            <ModalBody >
              <ObsForm
                observation={this.props.observation}  
                resetState={this.props.resetState}
                toggle={this.toggle}
              />
            </ModalBody>
          
          </Modal>
           
        </Fragment>
    );
  }
}

export default ObsModal;

//------------------------
